// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UsersDashboardNavbar from "../common/navbar/UsersDashboardNavbar.res.js";
import * as UserDashboardEditForm from "./form/UserDashboardEditForm.res.js";
import * as UserDashboardEditScss from "./UserDashboardEdit.scss";

var css = UserDashboardEditScss;

function UserDashboardEdit$default(props) {
  var user = props.user;
  console.log(user);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(UsersDashboardNavbar.make, {
                      selectedCategory: "User",
                      id: Caml_option.some(user.id)
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "Edit User: " + user.firstName + " " + user.lastName
                            }),
                        JsxRuntime.jsx(UserDashboardEditForm.make, {
                              user: user
                            })
                      ]
                    })
              ]
            });
}

var $$default = UserDashboardEdit$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
